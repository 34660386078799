export const testnetIbcChannels = [
  {
    key: "1",
    source_channel: "channel-4",
    destination_chain: "Osmosis",
    destination_chainid: "osmo-test-4",
    destination_channel: "channel-1838",
    known_operators: "",
  },
];
